$(()=> {

	let sliderArrow = {
		prevArrow: '<div class="c-slider__arrow c-slider__arrow--prev"></div>',
		nextArrow: '<div class="c-slider__arrow c-slider__arrow--next"></div>',
	}
	
	$('.c-slider').slick({
		fade: true,
		prevArrow: sliderArrow.prevArrow,
		nextArrow: sliderArrow.nextArrow,
	});

	$('.c-groups__nav-item').on('click', function(e) {
		e.preventDefault();
		let mod = 'c-groups__nav-item--active';
		let target = $(this).attr('data-target');

		if ($(this).hasClass(mod)) {
			if($(this).hasClass('c-groups__nav-item--mobile')) {

				$(`#${target}`).removeClass('c-groups__unit--active').slideUp();
				$(this).removeClass(mod);
			} 
			return true;
			 
		} else {
			$(this).addClass(mod).siblings().removeClass(mod);

			if($(`#${target}`).length > 0) {
				$(`#${target}`)
					.slideDown(400, () => { $(`#${target}`).addClass('c-groups__unit--active')})
					.siblings('.c-groups__unit').slideUp(400, function() {
						$(this).removeClass('c-groups__unit--active')
				})
			}
		}

		
	});

	if ($('.c-groups__nav').length > 0 && $(window).outerWidth() > 767) {
		$('.c-groups__nav .c-groups__nav-item').eq(0).addClass('c-groups__nav-item--active'); //.trigger('click')
		$('.c-groups .c-groups__unit').eq(0).addClass('c-groups__unit--active'); //.trigger('click')
	}

	$('.c-hits-slider').slick({
		slidesToShow: 4,
		slidesToScroll: 4,
		dots: false,
		prevArrow: sliderArrow.prevArrow,
		nextArrow: sliderArrow.nextArrow,
		responsive: [
	    {
	      breakpoint: 1024,
	      settings: {
	        slidesToShow: 3,
	        slidesToScroll: 3,
	      }
	    },
	    {
	      breakpoint: 993,
	      settings: {
	        slidesToShow: 2,
	        dots: true,
	        arrows: false,
	        slidesToScroll: 2,
	      }
	    },
	    {
	      breakpoint: 767,
	      settings: {
	        slidesToShow: 1,
	        slidesToScroll: 1,
	        arrows: false,
	        dots: true
	      }
	    },
		]

	});

	if ($('.c-product__slider').length > 0) {
		let modifer = 'c-product__slider-item--active';
		let box = $('#product_photo');
		let gallery = [];

		$('.c-product__slider-item').each((a, b)=> {
			gallery.push($(b).attr('data-src-lg'))
		});



		box.attr('src', $('.c-product__slider-item').eq(0).attr('data-src'))
			.attr('data-idx', 0)
			.attr('data-gallery', gallery.join('|'));

		$('.c-product__slider').slick({
			slidesToShow: 4,
			slidesToScroll: 4,
			prevArrow: sliderArrow.prevArrow,
			nextArrow: sliderArrow.nextArrow,
			responsive: [
		    {
		      breakpoint: 767,
		      settings: {
		        arrows: false,
		        dots: true
		      }
		    }
		  ]
		});

		$('.c-product__slider-item').on('click', function() {
			let src = $(this).attr('data-src'); 
			$(this).addClass(modifer).siblings().removeClass(modifer);

			box.attr('src', src);
			box.attr('data-idx', $(this).attr('data-slick-index'));
		});
	}

	$('#product_photo').on('click', function() {
		let self = $(this);
		let group = self.attr('data-gallery').split('|');
		$.fancybox(group, {
			index: +self.attr('data-idx')
		})
	});

	$('.c-tabs__btn').on('click', function(e) {
		e.preventDefault();

		let target = $(this).attr('href');
		let mod = 'c-tabs__btn--active';
		let modContent = 'c-tabs__unit--active';
		let current = $(this).closest('.c-tabs').find(`.${modContent}`); 

		if($(this).hasClass(mod)) { return }

		$(current).removeClass(modContent);

		$(this).addClass(mod).siblings().removeClass(mod);

		$(target)
			.addClass(modContent).stop(true).fadeIn(400)
			.siblings().stop().fadeOut(300);

	});

	$('.c-widecard-slider').slick({
		slidesToShow: 3,
		slidesToScroll: 3,
		dots: false,
		prevArrow: sliderArrow.prevArrow,
		nextArrow: sliderArrow.nextArrow,
		responsive: [
			{
			  breakpoint: 993,
			  settings: {
			    slidesToShow: 2,
			    dots: true,
			    arrows: false,
			    slidesToScroll: 2,
			  }
			},
	    {
	      breakpoint: 767,
	      settings: {
	        slidesToShow: 1,
	        dots: true,
	        arrows: false,
	        slidesToScroll: 1,
	      }
	    },
	  ]
	});

	
	let flyoutInterval;


	function hideFlyout (el) {
		$(el).stop(true, true).slideUp();

	} 


	$('.c-nav__item[data-flyout]').on('mouseenter', function() {
		let content = '#' + $(this).attr('data-flyout');
		let top = $(this).offset().top + $(this).height() - 26;

		let treangle = $(content).find('.c-flyout__treangle');
		let addOffset = $(treangle).hasClass('inner') ? $('.c-header__grid').offset().left : $('.c-nav').offset().left;

		
		$(treangle).css({
			left: $(this).offset().left - addOffset + $(this).width()/2 - 29
		});
		$(content).css({ top: top}).stop(true, true).slideDown();
	});

	$('.c-nav__item[data-flyout]').on('mouseleave', function(e) {
		let content = '#' + $(this).attr('data-flyout');
		
		if ($(e.relatedTarget).closest('.c-flyout').length === 0) {
			flyoutInterval = setTimeout(function() {
				hideFlyout(content)
			}, 500)
		}
		
	});

	$('.c-flyout').on('mouseleave', function(e) {
	
		hideFlyout($(this))
	});

	$('.c-flyout').on('mouseenter', function(e) {
		clearTimeout(flyoutInterval);
	});

	$('.c-header__burger').on('click', function() {
		$('.c-nav').addClass('c-nav--on-show');
		$('body').addClass('c-page--on-menu');
	});

	$('.c-nav__mobile-btn--close').on('click', function() {
		$('.c-nav').removeClass('c-nav--on-show');
		$('body').removeClass('c-page--on-menu');
	});

	$('.c-tabs').each(function(idx, el) {
		$(el).find('.c-tabs__btn').eq(0).trigger('click');
		console.log("el: ", el);
	})
});

